import { format } from 'date-fns';
import { debounce } from "lodash";


export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const httpToHttps = (url) => {
  return url.replace('http://', 'https://');
}

export const getStatusClass = (status) => {
  switch (status) {
    case 'INSERITO': //inserito
      return 'status-inserted';
    case 'LAVORAZIONE': //lavorazione
        return 'status-processing';
    case 'LETTERA VETTURA': //lettera vettura
      return 'status-waybill';
    case 'PARZIALMENTE EVASO': //or processed  //evaso
      return 'status-part-dispatched';
    case 'EVASO': //or processed  //evaso
      return 'status-dispatched';
    case 'RESO': //or processed  //evaso
      return 'status-returned';          
    // case 'partially returned': //or processed  //evaso
    case 'RESO PARZIALE': //or processed  //evaso
      return 'status-part-returned';
    default:
      return '';
  }
};

export const getStatusColor = (status) => {
  const colorMap = {
    'all': 'red',
    'INSERITO': "rgba(114, 165, 207, 0.5)",
    'LAVORAZIONE': "rgba(238, 186, 105, 0.5)",
    'LETTERA VETTURA': "rgba(207, 201, 99, 0.5)",
    'PARZIALMENTE EVASO': "rgba(200, 200, 200, 0.5)",
    'EVASO': "rgba(213, 213, 213, 0.5)",
    'RESO': "rgba(104, 33, 122, 0.5)",
    'RESO PARZIALE': "rgba(171, 132, 174, 0.5)"
  };
  return colorMap[status];
};

export const formatDateToYMD = (date) => {
  return format(date, 'yyyy-MM-dd');
};

export const formatDateToYMDHM = (date) => {
  const now = new Date();
  date.setHours(now.getHours(), now.getMinutes());
  return format(date, "yyyy-MM-dd HH:mm");
};

export const isArraysEqual = (a, b) => 
    a.length === b.length && a.every((val, index) => val === b[index]);


//scalar to PDF converter, saves the PDF in the downloads and automatically tries to opne it
// export const downloadAndOpenPdf = (scalar) => {
//   // Decode Base64
//   const byteCharacters = atob(scalar);
//   const byteNumbers = new Uint8Array(byteCharacters.length);

//   for (let i = 0; i < byteCharacters.length; i++) {
//       byteNumbers[i] = byteCharacters.charCodeAt(i);
//   }

//   // Create Blob and Object URL
//   const blob = new Blob([byteNumbers], { type: "application/pdf" });
//   const blobUrl = URL.createObjectURL(blob);

//   // Create a hidden <a> element to trigger the download
//   const link = document.createElement("a");
//   link.href = blobUrl;
//   link.download = "labels.pdf"; // Forces download
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);

// };


export const downloadAndOpenPdf = (scalar, fileName = "labels.pdf") => {
  try {
    // Decode Base64
    const byteCharacters = atob(scalar);
    const byteNumbers = new Uint8Array(byteCharacters.length);
    
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    
    // Create Blob and Object URL
    const blob = new Blob([byteNumbers], { type: "application/pdf" });
    const blobUrl = URL.createObjectURL(blob);
    
    
    // Method 1: Try opening in a new tab first + download
      //download
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
      //if not firefox, open blob in new tab
    const isFirefox = typeof InstallTrigger !== 'undefined';
    if (!isFirefox) {      
      window.open(blobUrl, '_blank')
    }
    
    // const newTab = window.open(blobUrl, '_blank');
    // If popup is blocked or new tab doesn't open
    // if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
    //   // Method 2: Use iframe for inline viewing
    //   const iframe = document.createElement('iframe');
    //   iframe.style.display = 'none';
    //   iframe.src = blobUrl;
    //   document.body.appendChild(iframe);
      
    //   // Try to print the document (often triggers PDF viewer)
    //   setTimeout(() => {
    //     try {
    //       iframe.contentWindow.print();
    //     } catch (e) {
    //       console.log("Print method failed, trying download");
    //       // Method 3: Fall back to download
    //       const link = document.createElement('a');
    //       link.href = blobUrl;
    //       link.download = fileName;
    //       link.target = '_blank';
    //       link.click();
    //     }
        
    //     // Clean up the iframe after a delay
    //     setTimeout(() => {
    //       document.body.removeChild(iframe);
    //     }, 1000);
    //   }, 100);
    // }
    
    // Clean up the blob URL after a delay (give browsers time to load it)
    setTimeout(() => {
      URL.revokeObjectURL(blobUrl);
    }, 5000);
  } catch (error) {
    console.error("Error handling PDF:", error);
  }
};





//questo fa aprire 2 volte il file, rimosso se non va on mobile, try decomment

// Delay and attempt to open the file (May not work on all devices)
// setTimeout(() => {
//     window.open(blobUrl, "_blank"); // Tries to open after download
// }, 1000);

// // Example usage
// const response = {
//   SCALAR: "JVBERi0xLjcNCjIgMCBvYmoNClsvUERGIC9UZXh0IC9JbWFnZUIgL0ltYWdlQyAvSW1hZ2VJXQ0KZW5kb2JqDQo7..."
// };
// downloadAndOpenPdf(response.SCALAR);

// export const generateSizeDataXML = (items) => {
//   return `<SIZE_DATAS>${items.flatMap(item =>
//     item.SIZEZ.map(size => size.DETECTED_QTY != 0 
//       ? `<SIZE_DATA><ID_ITEM>${item.ID}</ID_ITEM><ID_SIZE>${size.ID_SIZE}</ID_SIZE><QTY>${size.DETECTED_QTY}</QTY></SIZE_DATA>`
//       : ''
//     )
//   ).join('')}</SIZE_DATAS>`
// }

export const generateSizeDataXML = (items) => {
  return `<SIZE_DATAS>${items.map(item =>
    (item.DETECTED_QTY && item.DETECTED_QTY != 0) 
      ? `<SIZE_DATA><ID_ITEM>${item.ID}</ID_ITEM><ID_SIZE>${item.ID_SIZE}</ID_SIZE><QTY>${item.DETECTED_QTY}</QTY></SIZE_DATA>`
      : ''
    ).join('')}</SIZE_DATAS>`
}


// General function to save any data to localStorage
export const saveToLocalStorage = debounce((key, data) => {
    try {
        localStorage.setItem(key, JSON.stringify(data));
        console.log(`Saved to LocalStorage [${key}]:`, data);
    } catch (e) {
        console.error(`Could not save ${key} to localStorage`, e);
    }
}, 2000); // 1 second debounce delay

// export default saveToLocalStorage;

export function getLocalStorageSizeInMB(key) {
  const item = localStorage.getItem(key);
  if (!item) return 0; // If key doesn't exist, size is 0 MB

  // Calculate size in bytes
  const sizeInBytes = new Blob([item]).size;
  const sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
  console.log("LocalStorage size:", sizeInMB, "MB");

  // // Convert to MB (1 MB = 1024 * 1024 bytes)
  // return (sizeInBytes / (1024 * 1024)).toFixed(2); // 2 decimal places
}

