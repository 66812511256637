import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from "../assets/images/logo.png"
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
// import {version} from '../utils/constants/version'
import packageJson from '../../package.json'
import { AuthContext } from '../contexts/LoginAuthProvider.jsx'
import Cookies from 'js-cookie'
import { ProductContext } from '../contexts/ProductProvider.jsx';
import config from '../utils/constants/config';
import useTranslate from '../utils/useTranslate.jsx';
import { endOfMonth, startOfMonth } from 'date-fns';

function Sidebar({isSidebarVisible, setIsSidebarVisible, burgerMenuRef}) {
    const translate = useTranslate();
    const navigate = useNavigate();
    const { logout } = useContext(AuthContext);

    const location = useLocation();
    const [activePath, setActivePath] = useState(location.pathname);
    const { setFilter, setSeasons, setDepartments, setSuppliers, setStockSearch, setTypeSearch, setStockArticles, setAvailableOnly, setStockPagination, setStockScrollPosition, 
        setFilterDash, setStockSeason, setStockDepartment, setStockSupplier,setSuppliersDash, setStores, setDashboardSupplier, setDashboardSupplierId, setDashboardStore, setDashboardStoreId,
    setCalendarDate, setTimeFrame, setSalesByStore, setSalesByChannel, setIsGross, setIsCumulative, setLoading, setDashboardRawData, setQRcode, setErrors, 
     setFilterOrders, setOrdersStatus, setOrdersStore,setOrdersStoreId, setOrdersChannel, setOrdersChannelId, setSaleChannels, setOrdersSearch, setOpenClosedOrders, setOrdersPagination, setOrdersCustScrollPosition, setOrdersItemScrollPosition, setVisualization, setOrdersLoading, setNoResultsMessageOrders, setDatesRange,
     setInventoryBarcode, setInventorySearch, setInventoryApi, setUpdatedInventoryList,
     setInternalBarcode, setManufacturerBarcode,
     setReprintLabelsStore, setReprintLabelsStoreId,
     setReprintLabelsSearchText, setReprintLabelsTypeSearch, setReprintLabelsPagination, setReprintLabelsArticles,
     setReprintLabelsBarcode, setUpdatedLabelsList, setSelectedLabelFormat,
     setManualStockLabelPrint, setQRscannedItem, setStoresWithErpCode, setStoreBarcode, setReprintLabelsSeason, setReprintLabelsSeqGrouped
     } = useContext(ProductContext);
    const sidebarRef = useRef(null)
    
    const user = Cookies.get('company_name');
    //converts role from cookie string into number
    const role = parseInt(Cookies.get('role'), 10);
    const roleMap = {
        0: 'admin',
        1: 'admin',
        300: 'store',
        550: 'customer'
    };
    const roleString = translate(roleMap[role] || '');

    const now = new Date()

    // let roleString;

    // switch (role){
    // case 0:
    //     roleString = translate('admin');
    //     break;
    // case 1:
    //     roleString = translate('admin');
    //     break;
    // case 300:
    //     roleString = translate('store');
    //     break;
    // case 500:
    //     roleString = translate('customer');
    //     break;
    // default: '';

    // }


    //   const menuItems = [
    //     { title: 'Product Lookup', subtitle: 'Items and stocks', path: '/product-lookup', icon: 'cube' },
    //     { title: 'Sales Dashboard', subtitle: 'Sales results', path: '/sales-dashboard', icon: 'line-chart' },
    //     { title: 'Customer', subtitle: 'Insert data', path: '/customer', icon:'male' },
    //   ];

    const handleItemClick = (path) => {
    setActivePath(path);
    setIsSidebarVisible(false);
    navigate(path)
    };

    const handleLogout = () => {
    //resets all values from provider aka the ones which persist through the app even after logout, as they are in the provider

    setFilter(      
        {
            season: config.all, //at new login, cookie filter will be undefined hence set at last season (while local fileter will be all)
            department: config.all,
            supplier: config.all,
        })

    //reset data to default (provider)
    setSeasons([])
    setDepartments([])
    setSuppliers([])

    setStockSearch('')
    setTypeSearch('Item')

    setStockArticles([])

    setAvailableOnly(false)

    setStockPagination(1)
    setStockScrollPosition(0)
    
    setStockSeason('')
    setStockDepartment('')
    setStockSupplier('')
    
    // setActiveRequestCount(0)
    // setLoadPanelVisible(false)
    setLoading(false)
    // setNoResultsMessage(false)

    setFilterDash(
        {
            supplier: config.all,
            store: config.all,
        }
    )
    setSuppliersDash([])
    setStores([])

    setDashboardSupplier('')
    setDashboardSupplierId('')
    setDashboardStore('')
    setDashboardStoreId('')

    setCalendarDate(new Date())
    setTimeFrame('day')

    setSalesByStore([])
    setSalesByChannel([])

    Cookies.set('dashboard-is_gross', undefined)
    Cookies.set('dashboard-is_cumulative', undefined)
    setIsGross(Cookies.get('dashboard-is_gross'))
    setIsCumulative(Cookies.get('dashboard-is_cumulative'))

    setDashboardRawData([])
    setQRcode('')
    setErrors(null)

    setSaleChannels([])
    setOrdersStatus('')
    setOrdersStore('')
    setOrdersStoreId('')
    setOrdersChannel('')
    setOrdersChannelId('')
    setOrdersSearch('')
    setOpenClosedOrders('open')

    setOrdersPagination(1)
    setOrdersCustScrollPosition(0) 
    setOrdersItemScrollPosition(0)

    setVisualization('customer')
    setOrdersLoading(false)
    setNoResultsMessageOrders(false)
    setFilterOrders({
        store: config.all,
        channel: config.all,
        status: config.all,
    })
    setDatesRange({
        startDate: startOfMonth(now),
        endDate: endOfMonth(now),
    })

    setInventoryBarcode('')
    setInventorySearch('')
    setInventoryApi({})
    setUpdatedInventoryList([])


    setInternalBarcode('')
    setManufacturerBarcode('')

    setReprintLabelsStore(null), 
    setReprintLabelsStoreId(null),
    setReprintLabelsSearchText('')
    setReprintLabelsTypeSearch('Barcode')
    setReprintLabelsPagination(1)
    setReprintLabelsArticles([])
    // setReprintLabelsBarcode('')
    setUpdatedLabelsList([])
    setSelectedLabelFormat('')
    setManualStockLabelPrint('from detection')
    setQRscannedItem({})
    setStoresWithErpCode([])
    setStoreBarcode('')
    localStorage.removeItem('labelsList')
    setReprintLabelsSeason('')
    setReprintLabelsSeqGrouped(null) // leave it from local storage for now
    localStorage.removeItem('reprintLabelsSeqGrouped')




    logout(); //needs to be the last, else navigate function is executed before the array are set to empty

    // console.log(    
    //     'logout cookies',
    // Cookies.get('token'),
    // Cookies.get('url'),
    // Cookies.get('currency'),
    // Cookies.get('language'),
    // Cookies.get('role'),
    // Cookies.get('id_store'),
    // Cookies.get('company_name'),
    // Cookies.get('stock-season')
// )

    }

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (sidebarRef?.current && !sidebarRef.current.contains(e.target) &&
                burgerMenuRef?.current && !burgerMenuRef.current.contains(e.target)) {
                setIsSidebarVisible(false); // Close sidebar if clicked or tapped outside
            }
            };
        
            // Attach event listeners for both mouse and touch interactions
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('touchstart', handleClickOutside); // For touch devices
        
            // Clean up event listeners when the component is unmounted or dependencies change
            return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
            };
    }, [burgerMenuRef, setIsSidebarVisible])
    



      
    
  return (
    <div ref={sidebarRef} id='sidebar' className={`d-flex flex-column justify-content-between ${isSidebarVisible? 'visible' : 'hidden' }`} >
        <ul className='list-unstyled navigation-item-border-top-parent'>
           {/* <div>  this div is needed to make the "first-child" css work */}
           {(role ==0 || role == 1 || role == 300) &&( // check chi deve vedere

            <li 
                className={`navigation-item ${activePath === '/product-lookup' ? 'active' : ''}`}
                onClick={() => handleItemClick('/product-lookup')}>
                <Link 
                    to={'/product-lookup'} 
                    className='d-flex align-items-center'
                >
                    <div className='me-4'>
                        {/* <i className={`icon fa-solid fa-cube`}></i> */}
                        <i className={`icon fa-solid fa-tag`}></i>
                    </div>
                    <div className='text-start'>
                        <h2 className='fs-5 fw- m-0'>{translate('Product Lookup')}</h2>
                        {/* <span>Items and stocks</span> */}
                    </div>
                </Link>
            </li>
           )}
            
            {(role ==0 || role ==1) &&( 
            <li 
                className={`navigation-item ${activePath === '/sales-dashboard' ? 'active' : ''}`}
                onClick={() => handleItemClick('/sales-dashboard')}>
                <Link to={'/sales-dashboard'} className='d-flex align-items-center'>
                    <div className='me-4'>
                        <i className={`icon fa-solid fa-line-chart`}></i>
                    </div>
                    <div className='text-start'>
                        <h2 className='fs-5 fw- m-0'>{translate('Sales Dashboard')}</h2>
                        {/* <span>Sales results</span> */}
                    </div>
                </Link>
            </li>
            )}





 {/* {(role ==0 || role == 1 || role == 300) &&( // check chi deve vedere
            <li 
                className={`navigation-item ${activePath === '/orders' ? 'active' : ''}`}
                onClick={() => handleItemClick('/orders')}>
                <Link to={'/orders'} className='d-flex align-items-center'>
                    <div className='me-4'>
                        <i className={`icon fa-solid fa-cube`}></i>
                    </div>
                    <div className='text-start'>
                        <h2 className='fs-5 fw- m-0'>{translate('Orders')}</h2>
                    </div>
                </Link>
            </li>
            )}

{(role == 0 || role == 1 || role == 300) &&( // check chi deve vedere
            <li 
                className={`navigation-item ${activePath === '/inventory' ? 'active' : ''}`}
                onClick={() => handleItemClick('/inventory')}>
                <Link to={'/inventory'} className='d-flex align-items-center'>
                    <div className='me-4'>
                        <i className={`icon fa-solid fa-list-check`}></i>
                    </div>
                    <div className='text-start'>
                        <h2 className='fs-5 fw- m-0'>{translate('Inventory')}</h2>
                    </div>
                </Link>
            </li>
            )}


{(role == 0 || role == 1 || role == 300) &&( // check chi deve vedere
            <li 
                className={`navigation-item ${activePath === '/barcode-association' ? 'active' : ''}`}
                onClick={() => handleItemClick('/barcode-association')}>
                <Link to={'/barcode-association'} className='d-flex align-items-center'>
                    <div className='me-4'>
                        <i className={`icon fa-solid fa-barcode`}></i>
                    </div>
                    <div className='text-start'>
                        <h2 className='fs-5 fw- m-0'>{translate('Barcode association')}</h2>
                    </div>
                </Link>
            </li>
            )} */}

{(role ==0 || role == 1 || role == 300 || role == 350) &&( 

            <li 
                className={`navigation-item ${activePath === '/reprint-labels' ? 'active' : ''}`}
                onClick={() => handleItemClick('/reprint-labels')}>
                <Link 
                    to={'/reprint-labels'} 
                    className='d-flex align-items-center'
                >
                    <div className='me-4'>
                        {/* <i className={`icon fa-solid fa-cube`}></i> */}
                        <i className={`icon fa-solid fa-print`}></i>
                    </div>
                    <div className='text-start'>
                        <h2 className='fs-5 fw- m-0'>{translate('Reprint labels')}</h2>
                        {/* <span>Items and stocks</span> */}
                    </div>
                </Link>
            </li>
            )}

{(role ==0 || role == 1 || role == 300 || role == 550) &&( 

            <li 
                className={`navigation-item ${activePath === '/customer' ? 'active' : ''}`}
                onClick={() => handleItemClick('/customer')}>
                <Link to={'/customer'} className='d-flex align-items-center'>
                    <div className='me-4'>
                        <i className={`icon fa-solid fa-male`}></i>
                    </div>
                    <div className='text-start'>
                        <h2 className='fs-5 fw- m-0'>{translate('Customer')}</h2>
                        {/* <span>Insert data</span> */}
                    </div>
                </Link>
            </li>
            )}

                {/* {
                    menuItems.map((item, index) => (
                        <div 
                            key={index} 
                            className={`navigation-item ${activePath === item.path ? 'active' : ''}`}
                            onClick={() => handleItemClick(item.path)}>
                            <Link to={item.path} className='d-flex align-items-center'>
                                <div className='me-4'>
                                    <i className={`icon fa fa-${item.icon}`}></i>
                                </div>
                                <div className='text-start'>
                                    <h2 className='fs-5 fw- m-0'>{item.title}</h2>
                                    <span>{item.subtitle}</span>
                                </div>
                            </Link>
                        </div>
                    ))
                } */}
        </ul>
        {/* </div> */}

        <ul className='list-unstyled navigation-item-border-top-parent'>
            <li 
                className={`navigation-item  ${activePath === '/settings' ? 'active' : ''}`}
                onClick={() => handleItemClick('/settings')}>
                <Link to={'/settings'} className='d-flex align-items-center'>
                    <div className='me-4'>
                        <i className={`icon fa-solid fa-gear`}></i>
                    </div>
                    <div className='text-start'>
                        <h2 className='fs-5 fw- m-0'>{translate('Settings')}</h2>
                        {/* <span>Insert data</span> */}
                    </div>
                </Link>
            </li>

            <li 
                className={`navigation-item ${activePath === '/faq' ? 'active' : ''}`}
                onClick={() => handleItemClick('/faq')}>
                <Link to={'/faq'} className='d-flex align-items-center'>
                    <div className='me-4'>
                        <i className={`icon fa-regular fa-circle-question`}></i>
                    </div>
                    <div className='text-start'>
                        <h2 className='fs-5 fw- m-0'>{translate('FAQ')}</h2>
                        {/* <span>Insert data</span> */}
                    </div>
                </Link>
            </li>

            <li>
                <div className='navigation-item'>
                    <div className='d-flex align-items-center' onClick={handleLogout}>
                        <div className='me-4'>
                            <i className={`icon fa-solid fa-sign-out`}></i>
                        </div>
                        <div className='text-start'>
                            <h2 className='fs-5 fw- m-0'>{translate('Logout')}</h2>
                            {/* <span>Exit</span> */}
                        </div>
                    </div>
                </div>    
            </li>


            <div>               
                <div className='d-flex justify-content-between'>
                    <div className='text-start ms-4 mt-3'>
                        {/* <h2 className='nunito-ff fs-24 py-4'>eversell</h2> */}
                        <div>
                        <img src={logo} alt="eversell logo"/>
                        </div>
                        <span>{`${translate('Version')} ${packageJson.version}`}</span>
                    </div>
                    <div className='mx-3 align-self-end pb-2 d-flex flex-column justify-content-center'>
                        {/* <p className='small'>{`${user}${roleString ? `, ${roleString}` : ''}`}</p> */}
                        <span>
                            <i className='fa-solid fa-user'></i>
                        </span>
                        <p className='fs-09'>{`${user}`}</p>
                        <p className='fs-09'>{`${roleString ? `${roleString}` : ''}`}</p>
                        {/* <h2 className='nunito-ff text-white fs-4'>eversell</h2> */}
                    {/* <img src={logo} alt="eversell logo"/> */}
                    </div>
                </div>
            </div>
        </ul>
    </div>
  )
}

export default Sidebar

Sidebar.propTypes = {
    isSidebarVisible: PropTypes.bool.isRequired,
    setIsSidebarVisible: PropTypes.func.isRequired,
}