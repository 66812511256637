// import { Button } from 'devextreme-react/button'
// import useTranslate from '../utils/useTranslate'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import ButtonsList from './ButtonsList'
import { ProductContext } from '../contexts/ProductProvider'
import { isArraysEqual } from '../utils/functions'


const ReprintLabelsFooter = (
  // {firstBtnType, firstBtnText, firstBtnFunction, secondBtnType, secondBtnText, secondBtnFunction}
  {buttonsList, reprintLabelsSeqGrouped, setIsSeqGroupedPopupVisible, setIsNewListPopupVisible, page}
) => {

  const showSeqGroupPopup = () => {
    setIsSeqGroupedPopupVisible(true)
  }

  const startNewList = () => {
    setIsNewListPopupVisible(true)
  }

  // useEffect(()=>{
  //   if(!reprintLabelsSeqGrouped){
  //     showSeqGroupPopup()
  //   }
  // },[])
  

  // const { updatedLabelsList } = useContext(ProductContext)
  // const buttonsList = useMemo(() => [
  //   {
  //     id: '1',
  //     type: 'normal', //"normal", "success", "default", "danger"
  //     text: 'exit',
  //     function: () => handleExit(),
  //     stylingMode: '', //default is "contained"
  //     className: 'w-40',
  //   },
  //   {
  //     id: '2',
  //     type: 'default', //"normal", "success", "default", "danger"
  //     text: 'continue', //complete detection
  //     // function: () => {},
  //     function: () => handleContinue(), //TODO: scrivere funzione per concludi rilievo
  //     stylingMode: '', //default is "contained"
  //     className: 'w-40',
  //     disabled: updatedLabelsList && updatedLabelsList.length > 0 ? false : true
  //   }
  // ] , [updatedLabelsList])

 
    return (
      buttonsList &&
      <div className="footer-menu d-flex justify-content-evenly align-items-center">

        {page == 1 && (
          <div className="notification-bell" onClick={showSeqGroupPopup}>
            <i className={`fa-solid ${!reprintLabelsSeqGrouped ? 'fa-square' : ''} ${reprintLabelsSeqGrouped == 'seq' ? 'fa-list' : ''} ${reprintLabelsSeqGrouped == 'grouped' ? 'fa-sitemap' : ''} fs-18 grey-blue`}></i>
          </div>
        )}
        
        <ButtonsList buttonsList={buttonsList} />

        {page == 1 && (
          <div className="notification-bell" onClick={startNewList}>
            <i className='fa-solid fa-square-plus fs-18 grey-blue'></i>
          </div>
        )}
        </div>
      
  )
}

export default ReprintLabelsFooter