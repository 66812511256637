import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import useTranslate from '../utils/useTranslate';
import { motion } from 'framer-motion';
import notify from 'devextreme/ui/notify';
import { ProductContext } from '../contexts/ProductProvider';
import Item from '../api/ApiCallsProductLookup';
import Cookies from 'js-cookie';
import ConfirmSavePopup from './ConfirmSavePopup';
import { useBlocker, useNavigate } from 'react-router-dom';
import LookupTextbox from './LookupTextbox';
import { Button } from 'devextreme-react';
// import DropDownItemBarcodeLabels from './DropDownItemBarcodeLabels';
import QRbuttonWithList from './QRbuttonWithList';
import DetectedItemsListCard from './DetectedItemsListCard';
import ReprintLabelsList from './ReprintLabelsList';
import ReprintLabelsFooter from './ReprintLabelsFooter';
import ReprintLabelsConfirmPage from './ReprintLabelsConfirmPage';
import TabNavigation from './TabNavigation';
import { downloadAndOpenPdf, generateSizeDataXML } from '../utils/functions';
import FilterPopup from './FilterPopup';


const animationProps = {
  initial: { x: '-100%', opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: '100%', opacity: 0 },
  transition: { duration: 0.3 }
}

const MainReprintLabels = ({store, isChooseStorePopupVisible, setIsChooseStorePopupVisible, startFromLabelNumber, setStartFromLabelNumber, setIsSeqGroupedPopupVisible, setIsNewListPopupVisible}) => {
  const translate = useTranslate();
  // const { getData } = Item();
  const { getReport } = Item();
  const navigate = useNavigate();
  // const printOptionsFormRef = useRef(null)

  const [page, setPage] = useState(1)

  const [isLoading, setLoading] = useState(false);
  const [isLoadingPrint, setLoadingPrint] = useState(false)
  const [noResultsMessage, setNoResultsMessage] = useState(false);
  const [noSelectedMessage, setNoSelectedMessage] = useState(true);
  const [errors, setErrors] = useState(null)

  const [article, setArticle] = useState(null);

  const { reprintLabelsStore, reprintLabelsStoreId,
    reprintLabelsSearchText, setReprintLabelsSearchText, reprintLabelsTypeSearch, setReprintLabelsTypeSearch, 
    reprintLabelsPagination, setReprintLabelsPagination, reprintLabelsArticles, setReprintLabelsArticles,
    reprintLabelsBarcode, setReprintLabelsBarcode, 
    updatedLabelsList, setUpdatedLabelsList, selectedLabelFormat, manualStockLabelPrint, addItemToLabelsList, fetchItemWithSizes, seasons, reprintLabelsSeason, isLoadingItemWithSizes, reprintLabelsSeqGrouped
  } = useContext(ProductContext);
  
  const [activeRequestCount, setActiveRequestCount] = useState(0); 
  const abortControllerRef = useRef(null);

  const [isSavePopupVisible, setSavePopupVisibility] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(false);

  const [PDFscalar, setPDFscalar] = useState()

  const [isSeasonsPopupVisible, setIsSeasonsPopupVisible] = useState(false)

  // const role = 0
  const role = parseInt(Cookies.get('role'), 10);
  const roleMap = {
      0: 'admin',
      1: 'admin',
      300: 'store',
      550: 'customer'
  };
  const roleString = roleMap[role] || '';


  //get inventoryList if invebìntory is ongoing
  // const 
  // useEffect(() => {
  //   const fetch = async () => {
  //     try {
  //       setLoading(true);
  //       setNoResultsMessage(false);
  //       setNoSelectedMessage(false);


  //quando cambia barcode in provider, parte chiamata per beccare articolo, articolo viene aggiunto alla lista



// MOVED TO PRODUCT PROVIDER
  // triggered by OK btn click on detectedItemCard, adds item as 1st item of list, with its own local id
  //identifica l'item dalla lista, aggiunge alla lista un ogg con ID locale e id obj/barcode

  // const addItemToLabelsList = useCallback((selectedItem) => {
  //   console.log('print selected item', selectedItem);
    
  //   if(selectedItem && selectedItem != null){
  //     let localUpdatedList = [...updatedLabelsList]
  //     //check if item already present
  //     const existingItemIndex = updatedLabelsList.findIndex(item => item.BARCODE == selectedItem.BARCODE)
  //     //if code not already present. add article to beginning of list
  //     if(existingItemIndex == -1) {
  //       // New item, set DETECTED_QTY to 1 and add to the list
  //       selectedItem.DETECTED_QTY = 1;
  //       localUpdatedList = [selectedItem, ...updatedLabelsList]
  //     console.log('updated list mmidd' , localUpdatedList);
  //     } else {
  //       // Existing item, increase DETECTED_QTY
  //       localUpdatedList[existingItemIndex].DETECTED_QTY += 1;
  //     }



  //     setUpdatedLabelsList(localUpdatedList)
  //     notify({
  //         message: translate('Item added successfully'),
  //         position: {
  //           at: "top center",
  //           my: "top center",
  //           of: "#notify-container",
  //           // offset: "0 200"
  //         }
  //       }, 'success', 2000)

  //     // setArticle(null) // update article
  //     setReprintLabelsBarcode('') //reset barcode

  //     console.log('updated list ' , localUpdatedList);
      
  //   }
  // }, [updatedLabelsList]) 
  








  //get 1 article using barcode
  // takes the barcode from the provider and passes it to the api call, save the current article and updates the inventory list 
  //MOVED TO PROVIDER 
  // const fetchItemFromQR = async () => {
  //   // setActiveRequestCount(prevCount => prevCount + 1); //keep trak of aborted calls to set loader properly

  //   setLoading(true);
  //   setErrors(null)
  //   setNoResultsMessage(false)
  //   // const localBarcode = reprintLabelsBarcode ? reprintLabelsBarcode : '';
  //   const localBarcode = reprintLabelsBarcode 
  //   const localIdStore = reprintLabelsStoreId


  //   //TODO: capire se vogliamo aborter o se vogliamo che prima finisca la chiamata prima
  //   try {

  //       // if (abortControllerRef.current) {
  //       //   abortControllerRef.current.abort();
  //       // }
  //       // abortControllerRef.current = new AbortController();
  //       // const signal = abortControllerRef.current.signal;
        
  //       let { success: success, data: data, error: error} = await getData(`{"$id":"1","TOP":0,"ID_STORE":"${localIdStore}","ID_PRICING_ITEM":"efc0ce89-f8c4-4ae0-a71d-478854827877","LAST_SELL":0,"STOCK":0,"ORDERED":0,"SEASON":"","FREE_SEARCH":"","ID_ITEM":"","CODE":"","SEASON_LIST":"","REPARTO":"","BRAND":"","BARCODE":"${localBarcode}","P_LENGTH":"1","P_NUMBER":"1"}`
  //       // , signal
  //     ); 
  //       // if(success){
  //           if (data && data.length > 0) {
  //               addItemToLabelsList(data[0]); //directly add the item scanned from barcode to the list, instead of saving it temporally in article
  //           } else if(data && data.length == 0) {
  //               setNoResultsMessage(true)
  //           }
  //       // } else {
  //       //     setErrors(error.message);
  //       // }
  //   } catch (error) {
  //       notify(translate('Error in fetching data'), 'error', 3000);  //ITA Errore nel recupero dei dati
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  //every time reprintLabelsBarcode changes bc a new barcode has been read, a call to fetch the item is sent and the item is then added to the list
  // useEffect(() => {
  //   fetchItemFromQR()
  //   addItemToLabelsList(article)
  // }, [reprintLabelsBarcode])
  
  
  //to check the current number of ongoing calls (avoids wrong isLoading setting upon abort)
  // useEffect(() => {
  //   setLoading(activeRequestCount > 0)
  // }, [activeRequestCount])
  
  
  // useEffect(() => {
  //   if(reprintLabelsBarcode) { //if there is a barcode then the search result is either an obj or an empty obj/undefined
  //     const delayTimer = setTimeout(() => {
  //     setNoSelectedMessage(false)
  //     // fetchArticle() //after 3 seconds
  //     }, 500);
  //     return () => clearTimeout(delayTimer);
  //   } else { 
  //     setNoSelectedMessage(true)
  //   }
  // }, [reprintLabelsBarcode])

  ////////////////////////////////////////////////

//handle save and navigation without saving

  // let blocker = useBlocker(
  //   ({ currentLocation, nextLocation }) =>
  //   //  !isArraysEqual(inventoryApi.ITEMS, updatedLabelsList) &&
  //     currentLocation.pathname !== nextLocation.pathname &&
  //     nextLocation.pathname != '/qr-scanner-list'
  // );

  // useEffect(() => {
  //   if(blocker.state == 'blocked'){
  //       setSavePopupVisibility(true)
  //   } else {
  //     setSavePopupVisibility(false)
  //   }
  // }, [blocker])
  
  // const showPopup = useCallback(() => {
  //  setSavePopupVisibility(true) 
  // }, []);

  // const hidePopup = useCallback(() => {
  //  setSavePopupVisibility(false) 
  //  setPendingNavigation(false);
  // }, []);

//capire se vogliamo in qualche modo salvare la lista da qualche parte --> si salva in stato e in local storage
  const handleContinue = useCallback(async() => {
    setPage(2)
  }, []) //removed blocker, updatedLabelsList
  
  const handleExitFooter = () => {
    //asks if you sure you want to exit w/o saving , as popup  
    // showPopup()
    navigate('/inventory')
  }

  const handleExitPopup = () => {
    // da via libera al blocco della navigazione, naviga in altra pg
    // https://reactrouter.com/en/main/hooks/use-blocker
    // blocker.proceed()
  }

  const handleCancelNavigation = () => {
    // hidePopup();
    // blocker.reset()
  }



// spostare nel provider unica ricerca con taglie, sia per 
//a seconda del typeSearch, se testo mette il valore del text nel campo freeserach, se barcode, mette lo stesso valore nel campo barcode, come la search del provider
  // const searchText = useCallback(async () => {
  //   setActiveRequestCount(prevCount => prevCount + 1); //keep trak of aborted calls to set loader properly
  //   setLoading(true);
  //   setErrors(null)

  //   let season = ''
  //   let department = ''
  //   let supplier = ''
  //   let localAvailableOnly = 0 

  //   let localLabelsSearch = reprintLabelsSearchText == undefined ? '' : reprintLabelsSearchText.replace(/\s+/g, '%');  // \s is white space, + is for multiple, /g is global ; this replaces all spaces with %, if multiple spaces in a row, just puts one %
  //   let localTypeSearch = reprintLabelsTypeSearch;
  //   let localPagination = reprintLabelsPagination;
  //   let localIdStore = reprintLabelsStoreId
  //   try {
  //     // Abort the previous request if it exists, when user types more chars in "stockSearch param"
  //     if (abortControllerRef.current) {
  //       abortControllerRef.current.abort();
  //     }
  //     abortControllerRef.current = new AbortController();
  //     const signal = abortControllerRef.current.signal;

  //     // let data = await getData(`{
  //     //   "$id": "1",
  //     //   "TOP": 0,
  //     //   "ID_STORE": "${Cookies.get('id_store')}",
  //     //   "ID_PRICING_ITEM": "efc0ce89-f8c4-4ae0-a71d-478854827877",
  //     //   "LAST_SELL": 0,
  //     //   "STOCK": 0,
  //     //   "ORDERED": 0,
  //     //   "SEASON": "${season}",
  //     //   "FREE_SEARCH": "${typeSearch === 'Item' ? localStockSearch : ''}",
  //     //   "ID_ITEM": "00000000-0000-0000-0000-000000000000",
  //     //   "CODE": "",
  //     //   "SEASON_LIST": "",
  //     //   "REPARTO": "${department}",
  //     //   "BRAND": "",
  //     //   "SUPPLIER": "${supplier}",
  //     //   "BARCODE": "${typeSearch === 'Barcode' ? localStockSearch : ''}",
  //     //   "P_LENGTH": "10",
  //     //   "P_NUMBER": "${stockPagination}"
  //     // }`, signal) //todo: check velocita chiamata con 10 20 50 items 

  //     //"STOCK":0
  //     let { success: success, data: data, error: error} = await getData(`{"$id":"1","TOP":0,"ID_STORE":"${localIdStore}","ID_PRICING_ITEM":"efc0ce89-f8c4-4ae0-a71d-478854827877","LAST_SELL":0,"STOCK":${localAvailableOnly},"ORDERED":0,"SEASON":"${season}","FREE_SEARCH":"${localTypeSearch==='Item'?localLabelsSearch:''}","ID_ITEM":"00000000-0000-0000-0000-000000000000","CODE":"","SEASON_LIST":"","REPARTO":"${department}","BRAND":"","SUPPLIER":"${supplier}","BARCODE":"${localTypeSearch==='Barcode'?localLabelsSearch:''}","P_LENGTH":"10","P_NUMBER":"${localPagination}"}`,signal) //todo: check velocita chiamata con 10 20 50 items 
  //     if(success){
  //     if(localPagination != 1){ //attualm the search call does not handle pages after the 1st, "loadMoreItems" does
  //       data = reprintLabelsArticles.concat(data) 
  //     }
  //     setReprintLabelsArticles(data);
  //     setNoResultsMessage(data.length == 0) //bool
  //   } else {
  //     setErrors(error?.message);
  //   }
  //   } catch (error) {
  //     // console.log('Request canceled GAGA PROVIDER ERR', error.name);
  //     if (!error.name === 'CanceledError') {  //if api call not aborted 
  //       notify(translate(error.message) || translate('Error'), 'error', 3000);
  //     }
  //   } finally {
  //     setActiveRequestCount(prevCount => prevCount - 1);
  //   }
  // }, [reprintLabelsSearchText, reprintLabelsTypeSearch, reprintLabelsArticles ]) 

  // useEffect(() => {
  //   setLoading(activeRequestCount > 0)
  // }, [activeRequestCount])


  



  const buttonsListPage1 = useMemo(() => [
      // {
      //   id: '1',
      //   type: 'normal', //"normal", "success", "default", "danger"
      //   text: 'exit',
      //   function: () => handleExit(),
      //   stylingMode: '', //default is "contained"
      //   className: 'w-40',
      // },
      {
        id: '2',
        type: 'default', //"normal", "success", "default", "danger"
        text: 'continue', //complete detection
        // function: () => {},
        function: () => handleContinue(),
        stylingMode: '', //default is "contained"
        className: 'w-40',
        disabled: updatedLabelsList && updatedLabelsList.length > 0 ? false : true
      }
    ] , [updatedLabelsList])

    
    const handlePrint = useCallback(async() => {
      setLoadingPrint(true);

      //validate necessary params // format, manual/stock, store
      if (!reprintLabelsStore || !reprintLabelsStoreId || !selectedLabelFormat || !manualStockLabelPrint || !startFromLabelNumber || updatedLabelsList.length < 1) {
        notify(translate('Please fill all required fields'), 'error', 4000);
        return;
      } else {
        console.log("i'm printing");
        
        // riceve param come login / getAccessVLevel, restituisce uno scalare

        // let localLabelsList = updatedLabelsList
        let localLabelsList = [...updatedLabelsList].reverse(); //to revert the orders of labels (on screen: 1st label is last read, on print i need 1st label to be 1st read)
        let idItem; //string or null
        let listIdItem; // string or null
        let idStore = reprintLabelsStoreId //string
        let localStartFromLabelNumber = startFromLabelNumber || 1
        let manualQty = manualStockLabelPrint == 'from detection' ? generateSizeDataXML(localLabelsList) : null
        let labelFormat = selectedLabelFormat
        let reportFolder = Cookies.get('company_login').toUpperCase() //folder of remote service that contains label formats
        let seqGrouped = reprintLabelsSeqGrouped == 'seq' ? 10 : null

        console.log('list to print', localLabelsList);


        if(localLabelsList.length == 1){
          idItem = localLabelsList[0].ID //string
          listIdItem = null
        } else if(localLabelsList.length > 1){
          idItem = null
          //concat item.ID with ;
          // listIdItem = localLabelsList.map(item => item.ID).join(';') // string
          const uniqueIds = [...new Set(localLabelsList.map(item => item.ID))]; //Set removes duplicates
          listIdItem = uniqueIds.join(';');
          
          // list_id_item = localLabelsList.reduce((acc, item, index) => acc + (index ? ";" : "") + item.ID, "");
          // for(let i = 0; i < localLabelsList.length; i++){
          //   list_id_item += (i == 0 ? "" : ";") + localLabelsList[i].ID
          // }
          console.log("list", listIdItem);
          
        } else {
          notify(translate('Error'), 'error', 3000);
        }

        // se item è uno aka updatedLabelList ha lenght 1, allora popola id_item, else null
        //  = localLabelsList.length == 1 ? 
        // se ci sono piu articoli, concatena gli id separandoli col ;
        

        // console.log('localLabelsList', localLabelsList)
        // console.log('id_item', idItem)
        // console.log('list_id_item', listIdItem)
        // console.log('id_store', idStore)
        // console.log('localStartFromLabelNumber', localStartFromLabelNumber)
        // console.log('manualQty', manualQty)
        // console.log('labelFormat', labelFormat)


        try {

          const params = {
            // BASE_URL: baseURLreports,
            // DOMAIN: "eversell-bi02",
            FORMAT: "PDF",
            PARAMETERS: {
              ID_ITEM: idItem,
              LIST_ID_ITEM: listIdItem,
              ID_STORE: idStore,
              COMPANY_STOCK: seqGrouped,
              MANUAL_QTY: manualQty,
              START_FROM_LABEL_NUMBER: localStartFromLabelNumber
            },
            // PASSWORD: password,
            REPORT_FOLDER: reportFolder,
            REPORT_NAME: labelFormat,
            // USER: "eversell-dev"
          };
          
          let { success, data, error } = await getReport(params);


          // let { success: success, data: data, error: error}  = await getReport(`{"BASE_URL":"${baseURLreports}","DOMAIN":"eversell-bi02","FORMAT":"PDF","PARAMETERS":{"ID_ITEM":${id_item},"LIST_ID_ITEM":${list_id_item},"ID_STORE":"${id_store}","COMPANY_STOCK":null,"MANUAL_QTY":${manualQty},"START_FROM_LABEL_NUMBER":${localStartFromLabelNumber}},"PASSWORD":"${password}","REPORT_FOLDER":"DEMO","REPORT_NAME":"REPORT_PRINT_LABEL","USER":"eversell-dev"}`) 
          if(data){
          setPDFscalar(data.SCALAR);
          } else {
            // notify(translate('Select a valid label format for your store') || translate('Error'), 'error', 3000);
            notify(translate('Error'), 'error', 3000);
            // notify(translate(error.message) || translate('Error'), 'error', 5000);
          }
          setLoadingPrint(false)
          downloadAndOpenPdf(data.SCALAR) //is the data from the api call
          notify("PDF downloaded in the Downloads folder", "success", 3000); //ideale sarebbe mettere il link alla posizione del documento per facilitarne l'apertura
          // localStorage.removeItem("labelsList") //removed, if problems in printing and then reload, list disappears
        } catch (error){
          setLoadingPrint(false)
          // notify(translate(error.message) || translate('Error'), 'error', 3000);
          // notify(translate('Select a valid label format for your store') || translate('Error'), 'error', 3000);
          notify(translate('Error'), 'error', 3000);
        }
        // try API call
        //    returns scalar
        //    if success, notify success --> direi di fare direttamente download e dichiarae il succcesso soo di quello 
        // notify("PDF downloaded! If the file does not open autoamtically, you can open it from your Downloads folder.", ); //ideale sarebbe mettere il link alla posizione del documento per facilitarne l'apertura

        // else notify error

      }
    },[reprintLabelsStore, reprintLabelsStoreId, selectedLabelFormat, manualStockLabelPrint, startFromLabelNumber, updatedLabelsList])

    const handleGoBack = () => {
        setPage(1)
    }


    const printButtonEnabled = useMemo(() => {
      return ((updatedLabelsList && updatedLabelsList.length > 0)  && reprintLabelsStore  && reprintLabelsStoreId && selectedLabelFormat && manualStockLabelPrint) 
    }, [updatedLabelsList, reprintLabelsStore, reprintLabelsStoreId, selectedLabelFormat, manualStockLabelPrint])

    const buttonsListPage2 = useMemo(() => [
      {
        id: '1',
        type: 'normal', //"normal", "success", "default", "danger"
        text: 'back',
        function: () => handleGoBack(),
        stylingMode: '', //default is "contained"
        className: 'w-40',
      },
      {
        id: '2',
        type: 'default', //"normal", "success", "default", "danger"
        text: 'print', //complete detection
        // function: () => {},
        function: () => handlePrint(),
        stylingMode: '', //default is "contained"
        className: 'w-40',
        disabled: !printButtonEnabled
        // validationGroup: 'printOptions'
      }
    ] , [printButtonEnabled, reprintLabelsStore, reprintLabelsStoreId, selectedLabelFormat, manualStockLabelPrint, startFromLabelNumber, updatedLabelsList]) //if remove dependencies, call params are not upd
  



    // const reprintLabelsTypeOptions = [
    //   { text: translate('Item'), value: "Item", visibleFor: [] },
    //   { text: translate('Barcode'), value: "Barcode", visibleFor: [] },
    // ];


    //if wanna add visibility by role, add rolestring to TabNavigation
    const reprintLabelsTypeOptions = [
      { value: "Barcode", visibleFor: [] },
      { value: "Item", visibleFor: [] },
    ];

    const changeReprintLabelsType = useCallback((item) => {
      // setSelectedOpenClosed(item)
      setReprintLabelsTypeSearch(item.value)
      setReprintLabelsSearchText('') //every time the type changes, clean the search field
      setReprintLabelsArticles([]) //every time the type changes, clean the search results
    }, [])


  

  //per load more items vedi product provider


  //funzione che al cambio della selezione del filtro stagione mi verifica se la selezionata è l'ultima. 
  // const showSeasonsPopup = () =>{
  //   setIsSeasonsPopupVisible(true)
  // }

  // const hideSeasonsPopup = () => {
  //   setIsSeasonsPopupVisible(false)
  // }

  const fetchItemAndAddIfBarcode = useCallback(async ()=>{
    const newItems = await fetchItemWithSizes(reprintLabelsSearchText, false)
    // if (reprintLabelsTypeSearch == 'Barcode' && reprintLabelsArticles[0]) {
    if (reprintLabelsTypeSearch == 'Barcode') {
      if(newItems.length > 0) {
        console.log('item', reprintLabelsArticles[0]); //undefined, gotta get directly
        console.log('newItem', newItems[0]);
        
        addItemToLabelsList(newItems[0])
        setReprintLabelsSearchText('')
        setReprintLabelsArticles([])
      } else {
        setReprintLabelsSearchText('')
        setReprintLabelsArticles([])
      }
    }
  },[fetchItemWithSizes, reprintLabelsSearchText, reprintLabelsTypeSearch, reprintLabelsArticles])

  // useEffect(()=>{
  //   if (reprintLabelsTypeSearch == 'Barcode' && reprintLabelsArticles[0]) {
  //     // addItemIfBarcode()
  //     console.log('poo', reprintLabelsArticles[0]);
      
  //     addItemToLabelsList(reprintLabelsArticles[0])
  //     setReprintLabelsSearchText('')
  //     setReprintLabelsArticles([])
  //   }
  // },[reprintLabelsTypeSearch, reprintLabelsArticles])
  
  return (
    <>

        {/* <div id='lookup-form' className='d-flex align-items-center bg-light-grey'>
        <InventoryTextbox/>
        {/* TODO move QR reader to separate section with tab navigation choice *
        <QRbutton
          additionalClasses='ms-2 me-1 fs-18'
          source="inventory" 
        />
      </div> */}
    {page != 2 ?
      (
        <>
        <div id='lookup-form' className='d-flex  flex-column align-items-center bg-light-grey extra-extra-height-lookup'>
        <div className='tab-nav-container px-2 mt-0'>
          <TabNavigation dataSource={reprintLabelsTypeOptions} onItemClick={changeReprintLabelsType} selectedValue={reprintLabelsTypeSearch} noMarginTop={true} 
          />
        </div>
        <div className='lookup-form w-100 d-flex align-items-center pt-0'>
          <LookupTextbox 
            searchText={reprintLabelsSearchText}
            setSearchText={setReprintLabelsSearchText}
            // onEnterKey={()=>fetchItemWithSizes(reprintLabelsSearchText, false)}
            onEnterKey={()=>fetchItemAndAddIfBarcode()}
            />
          {/* <DropDownItemBarcodeLabels 
            typeSearch={reprintLabelsTypeSearch}
            setTypeSearch={setReprintLabelsTypeSearch}
          // setPagination
            />  */}
          <Button
          type='default'
          text={translate('go')}
          // onClick={()=>fetchItemWithSizes(reprintLabelsSearchText, false)}
          onClick={()=>fetchItemAndAddIfBarcode()}
          stylingMode={"contained"}
          className={'ms-1'}
          />

          {reprintLabelsTypeSearch == 'Barcode' &&
          <QRbuttonWithList
            // icon='photo'
            // path='/qr-scanner'          
            // onClick={()=> {navigate('/product-lookup/qr-scanner')}}
            // className='ms-2 me-1 qr-camera-btn border-dx'
            additionalClasses='ms-3 me-1 fs-18'
            source="reprint-labels" 
            list={updatedLabelsList}
            // onQRscanned={fetchItemFromQR}
            // fieldType="search" //name not needed bc there is only 1 field to fill whi the read barcode in the whole page
            />
          }
          {/* {reprintLabelsTypeSearch == 'Item' &&
              <>
              {/* <button 
              type='button'
              onClick={()=>showSeasonsPopup()}
              className={`filter-btn border-dx ms-3 me-1 fs-18 ${reprintLabelsSeason != ''? 'selected' : 'grey'}`}
              style={{height: '34px', width: '34px'}}
              >
                  <i className="fa-solid fa-calendar-check"></i>
              </button>   ///////end this comment

              <div 
                        className={`filter-item flex-shrink-1`} 
                        onClick={() => showSeasonsPopup()}>

                        {reprintLabelsSeason == '<ALL>' || reprintLabelsSeason == '' ? (
                                    <>
                                    <div><i className={`fa-solid fa-calendar-check fs-18 grey`}></i></div>
                                    <p className='m-0 text-uppercase grey'>{translate('season')}</p>
                                    </>
                                ) : (
                                    <>
                                    <div><i className={`fa-solid fa-calendar-check fs-18 selected`}></i></div>
                                    <p className='m-0 selected'>{reprintLabelsSeason}</p>
                                    </>
                                )}   
                                </div>

              <FilterPopup 
                isPopupVisible={isSeasonsPopupVisible} //true if index matches, else false
                togglePopup={()=> hideSeasonsPopup()} 
                content={seasons}
                filterType='season'
                fromPage={'/reprint-labels'}
                // selectFilter={selectFilter}
                //differentially pass the content to the global popup based on the label of the filter
                />
              </>
          } */}
        </div>
        </div>
        <motion.div 
          className={`scroll-view`}
          id='stock-loadpanel-container'
          {...animationProps}
        >
        {/* <LoadPanel 
          visible={isLoading}
          hideOnOutsideClick={false}
          container='#stock-loadpanel-container'
          shading={true}
          shadingColor="rgba(0, 0, 0, 0.2)"
          /> */}
         {/*  {noResultsMessage ? (
          <p className='mt-4 fs-5 grey'>{translate('Products not found.')}</p>
          ) : (
        <> */}
        {/* do 2 different detected cards, one with list of items --> each has ok btn to add on top, that changes into added and adds item; the other with barcode has a single item
        no, fare un unica card con lista, se poi c' è un solo item tanto meglio  */}
        
        {reprintLabelsTypeSearch == 'Item' && (
          <DetectedItemsListCard 
            isLoading={isLoadingItemWithSizes} 
            noSelectedMessage={noSelectedMessage} 
            noResultsMessage={noResultsMessage}
            // item={article}
            itemsList={reprintLabelsArticles}
          />
        )}
          {updatedLabelsList &&
            <ReprintLabelsList 
            // list={updatedLabelsList} setList={setUpdatedLabelsList}
            />
          }
                {/* </>
              )} */}
        </motion.div>

        {/* <ConfirmSavePopup
          handleExit={handleExitPopup}
          // handleSave={handleSave}
          // openItemId={openItemId}
          isPopupVisible={isSavePopupVisible}
          hidePopup={handleCancelNavigation}
          blocker={blocker}
        /> */}
        <ReprintLabelsFooter 
          handleExit={handleExitFooter}
          handleContinue={handleContinue}
          roleString={roleString}
          buttonsList={buttonsListPage1}
          reprintLabelsSeqGrouped={reprintLabelsSeqGrouped}
          setIsSeqGroupedPopupVisible={setIsSeqGroupedPopupVisible}
          setIsNewListPopupVisible={setIsNewListPopupVisible}
          page={page}
          // inventoryApi={inventoryApi}
          // updatedLabelsList={updatedLabelsList}
        />
      </>
      ) : ( 
        <ReprintLabelsConfirmPage 
          setPage={setPage} 
          buttonsListPage2={buttonsListPage2}
          isChooseStorePopupVisible={isChooseStorePopupVisible} 
          setIsChooseStorePopupVisible={setIsChooseStorePopupVisible}
          role={role}
          startFromLabelNumber={startFromLabelNumber} 
          setStartFromLabelNumber={setStartFromLabelNumber}
          isLoading={isLoadingPrint}
          // printOptionsFormRef={printOptionsFormRef}
          />
      )}

    </>
  )
}

export default MainReprintLabels