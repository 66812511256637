import { AnimatePresence, motion } from 'framer-motion'
import useTranslate from '../utils/useTranslate';
import { useCallback, useEffect, useState } from 'react';



const faqs = [
//   { 
//     section: "Test",
//     title: "Test",
//     description: 'Test'
// },
// { 
//   section: "Test",
//   title: "Test",
//   description: 'Test'
// },
// { 
//   section: "Test",
//   title: "Test",
//   description: 'Test'
// },
// { 
//   section: "Test",
//   title: "Test",
//   description: 'Test'
// },
// { 
//   section: "Test",
//   title: "Test",
//   description: 'Test'
// },
// { 
//   section: "Test",
//   title: "Test",
//   description: 'Test'
// },
// { 
//   section: "Test",
//   title: "Test",
//   description: 'Test'
// },
// { 
//   section: "Test",
//   title: "Test",
//   description: 'Test'
// },
// { 
  //   section: "Test",
  //   title: "Test",
  //   description: 'Test'
  // },
  // { 
    //   section: "Test",
    //   title: "Test",
    //   description: 'Test'
    // },
    { 
      section: "Reprint labels",
      title: "What can I do?",
      description: "You can search for an item either by barcode or by name. You can print the labels based on stock quantities or based on your detected quantity (how many labels you've manually entered). See the references below for more information."
    },
    { 
        section: "Reprint labels",
        title: "Select a store / change selected store (admin)",
        description: 'If you have an admin account, you can choose the store to associate with label printing. To change it, click the store icon at the top right, next to the burger menu, and select the new store in the popup that appears. You can scan the store barcode, if available for your company, by clicking on the camera icon. You can also change the store by clicking the "edit" icon (sheet and pencil) on the print confirmation page.'
    },
    { 
      section: "Reprint labels",
      title: "Select the printing mode",
      description: 'You can print labels in the same sequence as you add the items ("SEQUEN.") or grouping them by item code and size ("GROUPED"). You can change the printing mode by clicking on the icon in the bottom-left. Warning: this will delete the current list and start a new one.'
    },
    { 
      section: "Reprint labels",
      title: "Start a new list",
      description: 'If you have a current items list and want to start a new one, click on the icon in the bottom-right (+). Warning: starting a new list will delete the current one.'
    },
    { 
      section: "Reprint labels",
      title: "Item search by barcode (manual)",
      description: 'In the top section, "barcode" is selected by default. Enter the full barcode in the designated field and click the "GO" button / enter key. The corresponding item (with size) will be added to the list.'
    },
    { 
        section: "Reprint labels",
        title: "Item search by barcode (scanning)",
        description: 'In the top section, "barcode" is selected by default. You can also search for the barcode using the camera. Click the camera icon next to the "GO" button, and a barcode scanner will open. Scan the barcode and choose whether to add the scanned item to the list ("ADD" button) or discard it ("CANCEL" button).'
    },
    { 
        section: "Reprint labels",
        title: "Item search by name",
        description: 'In the top section, select "item" instead of "barcode". Enter keywords for the item you want to search for and click "GO". The item or a list of items will appear below. Click "OK" to add the corresponding item. A popup will appear where you can enter the quantity per size.'
    },
    { 
        section: "Reprint labels",
        title: "Add / modify item quantities",
        description: 'Subsequent additions of quantities will be added to the current quantities, not replace them. You can modify the quantities in the current list of selected items in the designed field (field on the right).'
    },
    { 
        section: "Reprint labels",
        title: "Delete an item",
        description: 'To delete an item, swipe from right to left on the desired item. Confirm or cancel the deletion in the popup that will appear.'
    },
    { 
        section: "Reprint labels",
        title: "Print stock quantities",
        description: 'To print labels based on the stock quantity of an item, simply add the item once to the list and select the "print labels [from stock]" option in the confirmation page.'
    },
    { 
        section: "Reprint labels",
        title: "Print a specific number of labels per size",
        description: 'Enter the item and modify the quantities as described above. You can also modify the quantities on the print confirmation page. On this confirmation page, select the "print labels [from detection]" option.'
    },
    { 
        section: "Reprint labels",
        title: "I closed the app / reloaded the page, do I have to start the list from scratch?",
        description: 'No. The label list is automatically saved every 2 seconds. After reloading the page, you will be asked if you want to continue the previous list or start a new one. Starting a new one will automatically delete the previously saved list.'
    },
    { 
        section: "Reprint labels",
        title: "Label format",
        description: 'Choose the desired label format, which you know is available for your store. If the selected label format is not available for your store, an error message will appear. Simply select an available format and relaunch the print.'
    },
    { 
        section: "Reprint labels",
        title: "Start from label",
        description: 'Sets the position where printing should start. For example, printing from label 2 will skip the first label and start printing from label n 2.'
    }
    // { 
    //     section: "Reprint labels",
    //     title: "",
    //     description: ''
    // }
  ];

  //group the faqs by sections
  const groupedFAQs = faqs.reduce((acc, faq) => {
    if (!acc[faq.section]) {
      acc[faq.section] = [];
    }
    acc[faq.section].push(faq);
    return acc;
  }, {});

  const animationProps = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.3 }
  }

  const animationContent = {
    initial: { height: 0, opacity: 0 },
    animate: { height: 'auto', opacity: 1 },
    exit: { height: 0, opacity: 0 },
    transition: {
      duration: 0.3,
      ease: 'easeInOut'
    },
  }


const MainFaq = () => {
    const translate = useTranslate();  

    const [expandedFaq, setExpandedFaq] = useState(null); // Store the expanded FAQ
    // const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
    const handleAccordionExpansion = useCallback((faq) => {
        //   setIsAccordionExpanded(!isAccordionExpanded)
        setExpandedFaq((prevFaq) => (prevFaq === faq ? null : faq)); // Toggle FAQ expansion
        }, []) //isAccordionExpanded
    
    //on page load, scroll into the topic region
    useEffect(()=>{
      const hash = window.location.hash
      if(hash) {
        const element = document.querySelector(hash)
        if(element){
          element.scrollIntoView({behavior: "smooth"})
        }
      }
    },[])

  return (
    <>
    <motion.div 
        className="scroll-view scroll-view-no-footer px-3"
        {...animationProps}
    >        

    <div>
      {Object.entries(groupedFAQs).map(([section, items]) => (
        <div key={section} id={section.replace(/\s+/g, '-').toLowerCase()}> 
          <h2 className='py-4 m-0 fs-1'>{translate(section)}</h2>
          {items.map((faq, index) => (
            <div key={index} className="stock-item height-2-lh">
                <div 
                    className='stock-item-child d-flex justify-content-between align-items-center w-100 text-start height-2-lh'
                    onClick={() => handleAccordionExpansion(faq)}
                    >
                    <h5 className='fw-600'>{translate(faq.title)}</h5>
                    {/* arrow */}
                    <div 
                        className="arrow pe-0 pe-sm-5 flex-shrink-0"
                    >
                        <i 
                        className={`fa-solid fa-chevron-down accordion-chevron-icon ${expandedFaq == faq ? 'expanded' : ''}`}
                        ></i>
                    </div>
                </div>
              {/* <p>{translate(faq.description)}</p> */}
                <AnimatePresence>
                {expandedFaq == faq &&(
                <motion.div 
                    className='w-100' 
                    initial={animationContent.initial}
                    animate={animationContent.animate}
                    exit={animationContent.exit}
                    transition={animationContent.transition}
                    >
                    {/* {children} */}
                    <p className='faq-description fs-14 pt-2 pb-4'>{translate(faq.description).replace(/\. /g, '. \n')}</p>

                </motion.div>
                )}
                </AnimatePresence>
            </div>
          ))}
        </div>
      ))}
    </div>
    </motion.div>

</>
  )
}

export default MainFaq
  