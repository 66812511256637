import { LoadIndicator } from 'devextreme-react/load-indicator';
import useTranslate from '../utils/useTranslate';
import CardBig from './CardBig'
import InventoryItem from './InventoryItem';
import { httpToHttps } from '../utils/functions';
import { Button } from 'devextreme-react/button';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ProductContext } from '../contexts/ProductProvider';
import ConfirmationPopup from './ConfirmationPopup';
import { NumberBox, SelectBox } from 'devextreme-react';

// import {mockFetchedItemsWithSizes, mockFetchedItemsWithSizes100} from './trash/mockData'


const DetectedItemsListCard = ({isLoading, noSelectedMessage, noResultsMessage, 
  itemsList  //temporaneam sostituita da mock con taglie
}) => {
  // const itemsList = mockFetchedItemsWithSizes //temporaneam sostituita da mock con taglie




  const translate = useTranslate();
  const {addItemToLabelsList, reprintLabelsTypeSearch} = useContext(ProductContext)
  const [isChooseSizePopupVisible, setChooseSizePopupVisibility] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [editedSizes, setEditedSizes] = useState(null);

  // console.log('mockuppp', itemsList);


  const hasQuantity = useMemo(() => {
    return editedSizes?.some(size => size.DETECTED_QTY > 0) || false;
  }, [editedSizes]); //check che ci sia almeno un field con numero diverso da zero nell'array taglie

  const popupButtons= useMemo(() => [
    {
        id: '1',
        type: 'normal', //"normal", "success", "default", "danger"
        text: 'cancel',
        function: () => closeSizesPopup(),
        stylingMode: 'contained', //default is "contained"
        className: 'bg-grey w-40',
    },
    {
        id: '2',
        type: 'default', //"normal", "success", "default", "danger"
        text: translate('ok'),
        function: () => confirmAndClose(), //when clicked, saves value which populates the scritta negozio (setReprintLabelsStore), in remote const and cloces popup 
        stylingMode: 'contained', //default is "contained"
        className: 'w-40',
        disabled: hasQuantity ? false : true
    },
], [hasQuantity]) //reprintLabelsStoreId

//in select by item version (vs barcode), the currently detected qty must be set to 0
  const openSizesPopup = useCallback((item)=>{
    setSelectedItem(item)
    console.log('selected item', item);
      // if (item.SIZES) {
      //   setEditedSizes([...item.SIZES]);
      // }

      if (item.SIZEZ) {
        const updatedSizes = item.SIZEZ.map(size => ({
          ...size,
          DETECTED_QTY: 0
        }));
        setEditedSizes(updatedSizes);
      }

    setChooseSizePopupVisibility(true)
  },[])

//   const openSizesPopup = useCallback((item) => {
//     const itemCopy = { ...item, SIZES: item.SIZES.map(size => ({ ...size })) };
//     setSelectedItem(itemCopy);
    
//     setEditedSizes(itemCopy.SIZES.map(size => ({
//         ...size,
//         DETECTED_QTY: 0
//     })));

//     setChooseSizePopupVisibility(true);
// }, []);


  const closeSizesPopup = useCallback(() => { // on dropdown value change, setReprintLabelsStore to the value + setPopupVisisbility = false
    setChooseSizePopupVisibility(false)
    setEditedSizes(null)
  }, []);
  
  
  const confirmAndClose = useCallback(() => {
    if (selectedItem) {
      // Use the latest state value directly
      setEditedSizes(currentEditedSizes => {
        if (currentEditedSizes) {
          console.log('editedSizes insideConfAndClos', currentEditedSizes);
          
          // Create a new item with updated sizes
          const updatedItem = {
            ...selectedItem,
            SIZEZ: currentEditedSizes
          };
          addItemToLabelsList(updatedItem);
        }
        return null; // This will clear the editedSizes
      });
      
      setChooseSizePopupVisibility(false);
    }
  }, [selectedItem, addItemToLabelsList]);



    //gestione eccezioni es aggiungo 2 volte stesso item, che deve fare? sommare? sostituire? io sommerei, alternativa mostrare la quantità attuale da eventualm modificare? ha piu senso che sostituisca?
    


  const onValueChanged = useCallback((e, sizeId) => {
    setEditedSizes(prevSizes => 
      prevSizes.map(size => 
        size.ID_SIZE === sizeId 
          ? { ...size, DETECTED_QTY: e.value } 
          : size
      )
    );
  }, []);


  const renderPopupBody = useCallback(() => {
    return (
      <>
      <div className='container px-3 py-2'>
        <div className='row py-1'>
            <div className='col-4'>{translate('size')}</div>
            <div className='col-8 text-end'>{translate('qty to add')}</div>
        </div>

        {/* one row per size, one td per info */}
        {/* {selectedItem?.SIZES?.map((size) => ( //do not use index for index, because if user deletes a row somehow, then evrithing switches index and is chaos */}
        {editedSizes?.map((size) => ( //do not use index for index, because if user deletes a row somehow, then evrithing switches index and is chaos
            <div className='row lh-lg align-items-center justify-content-between py-1' key={size.ID_SIZE}>
              <div className='col-4 fw-bold'>{size.SIZE_CODE}</div>
                {/* <div className='col-2 fw-bold'>{size.SIZE_BARCODE}</div> */}
                {/* <div className='col text-end'>{size.DETECTED_QTY}</div> */}
                <div className='col-8 text-end p-0 labels-numberbox-container '>
                    <NumberBox
                        value={size.DETECTED_QTY == 0 ? '' : size.DETECTED_QTY}
                        min={0}
                        max={1000000000000}
                        showSpinButtons={false}
                        readOnly={false}
                        onValueChanged={(e) => onValueChanged(e, size.ID_SIZE)}
                        className='inventory-number-box pe-4'
                        valueChangeEvent='keyup'
                    >
                    </NumberBox>

              {/* <input type="text" value={size.DETECTED_QTY} className='text-end'/> */}
              </div>
            </div>
        ))}
    </div>
      
      </>

    )
  },[editedSizes, onValueChanged, translate]) //localStores

  useEffect(() => {
    console.log('editedSizes: ',editedSizes);
  }, [editedSizes])
  

  
  return (
    <CardBig title={translate('Detected item')} marginTopContent='0px' heightCustom='auto' maxHeight={'300px'}>
      {/* removed marginTitle='0px' paddingBottomContent='0px' */}
      <div id='notify-container'>
      {isLoading ? (
        <LoadIndicator/>
      ) : noResultsMessage ? (
        <p className="my-3 fs-5 grey">{translate('Product not found.')}</p>
      // ) : noSelectedMessage || itemsList?.length < 1 || itemsList == null ? ( // errata gestione dell'articolo non trovato 
      //   <p className="my-3 fs-5 grey">{translate('No product selected.')}</p>
      ) : (


      <ul className='stock-item-ul scroll-view-ul list-unstyled '>
      {itemsList.map((item, index) => (

        <li 
        key={index}
        className='stock-item order-item m-0 text-start hover-grey'
        // onClick={()=>navigate(`/product-lookup/${item.ID}`)}
        >
          
          <div className="stock-item-child d-flex justify-content-between align-items-center w-100">
            {/* <div className="stock-item border border-0 d-flex align-items-center flex-shrink-1 flex-grow-1 m-0 p-0 "> */}
            <div className='d-flex align-items-center flex-shrink-1 flex-grow-1 m-0 p-0 '>

              {/* image */}
              <div className="img-container d-flex justify-content-center align-items-center">
                <img src={httpToHttps(item.IMAGE_01)} alt="image" />
                </div>

              {/* data */}
              <div className="d-flex justify-content-between  text-start me-2 pe-0 pe-sm-4 flex-grow-1">
                <div>
                  <p className="text-uppercase fw-semibold fs-14 lh-1">
                    {item.CODE}
                    <span className="grey"> {item.AUX_CODE}</span>
                      {item.BASE_CODE != item.CODE && 
                        <span className='text-secondary fs-12'> {item.BASE_CODE}</span>
                      }
                  </p>
                  <div className="grey">
                    <p className="description fs-12">{item.DESCRIPTION}</p>
                    {/* <p className="variant fs-12">{item.VARIANT_DESCRIPTION}</p> */}
                    {/* <p className="supplier-name text-uppercase d-inline grey-emphasis fs-12">{item.BRAND}</p> */}
                    {/* <p className="text-uppercase fw-semibold">{item.SIZE || 'eeee'}</p> */}
                    {/* <p className="text-uppercase fw-semibold">{item.BARCODE || 'eeee'}</p> */}
                  </div>
                </div>
              </div>
            </div>
          
          { reprintLabelsTypeSearch == 'Barcode' ? (
            <Button type='default' className='px-3 flex-shrink-0' onClick={() => addItemToLabelsList(item)}>OK</Button>
          ) : (
            <>
            <Button type='default' className='px-3 flex-shrink-0' onClick={() => openSizesPopup(item)}>OK</Button>
            </>
          )
        }
          </div>
        </li>
      ))}
      </ul>
      )
    }
      </div>
    <ConfirmationPopup
      popupTitle={selectedItem?.CODE || ''} 
      popupMessage={renderPopupBody()}
      popupButtons={popupButtons}
      dependency={popupButtons} //allows the enable of btn when store is selected
      dependency2={editedSizes}
      isPopupVisible={isChooseSizePopupVisible}
      // hidePopup
      hideOnOutsideClick={false}
      // showTitle = {false}
      showCloseButton = {false} 
    />
    </CardBig>  

    
    )
}

export default DetectedItemsListCard



  
  
  // const confirmAndClose = useCallback(() => { // on dropdown value change, setReprintLabelsStore to the value + setPopupVisisbility = false
  //   if (selectedItem && editedSizes) {
  //     let localEditedSizes = editedSizes
  //     console.log('editedSizes insideConfAndClos', localEditedSizes);
      
  //     // Create a new item with updated sizes
  //     const updatedItem = {
  //       ...selectedItem,
  //       SIZES: localEditedSizes
  //     };
  //     addItemToLabelsList(updatedItem)
  //     // addItemToLabelsList(selectedItem)
  //     setChooseSizePopupVisibility(false)
  //     setEditedSizes(null);
  //   }
  // }, [selectedItem, editedSizes, addItemToLabelsList]);


  
    //quando clicco annullla, resetta l'array taglie e non fa piu nulla
    // quando clicco ok --> prende l'array taglie , prende l'item con quel id item nell'array (come fa ora?)
      // se non c'è gia nella lista da stampare, allora ad item sostituisce l'array sizes con quello salvato temporaneam
      // se c'è gia nella lista, per ogni taglia somma la cifra passata con la cifra dell'item temporaneo




  //OLD
  // const onValueChanged = useCallback((e, size) => {
  //   // Find the size in the selectedItem and update its DETECTED_QTY
    
  //   const updatedSizes = selectedItem?.SIZES?.map(itemSize => 
  //     itemSize.SIZE_ID === size.SIZE_ID 
  //       ? { ...itemSize, DETECTED_QTY: e.value } 
  //       : itemSize
  //   );
    
  //   // Update the selectedItem with the modified SIZES array
  //   setSelectedItem({
  //     ...selectedItem,
  //     SIZES: updatedSizes
  //   });
    
  //   // Check if there's at least one size with a quantity greater than zero
  //   const hasQuantity = updatedSizes.some(s => s.DETECTED_QTY > 0);
    
  //   // You might want to update the noSizes state based on this check
  //   // For example, you could create a setNoSizes function:
  //   // setNoSizes(!hasQuantity);
  // }, []);


//updates the selectedItem sizes value, when numberbox value changes
  // const onValueChanged = useCallback((e, size) => {
  //   setSelectedItem((prevItem) => {
  //     // if (!prevItem) return prevItem; //prevent error, return if null
  
  //     return {
  //       ...prevItem,
  //       SIZES: prevItem.SIZES.map((s) =>
  //         s.SIZE_ID === size.SIZE_ID ? { ...s, DETECTED_QTY: e.value } : s
  //       ),
  //     };
  //   });
  //   // setReprintLabelsStoreId(e.value)
  //   // setReprintLabelsStore(e.component.option("text"))
  // }, []);   





{/* <div className="d-flex flex-column justify-content-center align-items-center"> */}
  {/* {item.ITEM_QTY > 1 && (
    <p className="fs-5 pt-1">
      <span className="light-grey fs-12">
        {translate('qty')}:{' '}
      </span>
      {item.ITEM_QTY}
    </p>
  )} */}
{/* </div> */}